import { isEmpty } from "lodash";

async function postData(url = "", data = {}) {
  let headersData = {};
  const lang = (typeof window !== "undefined") ? localStorage.getItem("wpmlLanguage") : 'pl';
  if (!isEmpty(lang)) {
    headersData = {
      ...headersData,
      "wpmlLanguage": `wpmlLanguage ${lang}`,
    };
  }
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...headersData
    },
    body: JSON.stringify(data),
  })
  return await response.json()
}

export const getProduct = async (productID) => {
  // (async () => {
  return await postData(`${process.env.wordPressUrl}/graphql`, {
    query: `query GET_PRODUCT{
        product(id: "${productID}", idType: DATABASE_ID) {
            ... on SimpleProduct {
              id
              name
              price
              stockStatus
              stockQuantity
            }
          }
        }
       `,
  }).then(element => element.data)
}

export const checkStock = async (productID) => {
  const tmp = await getProduct(productID).then(data => data.product.stockStatus === 'IN_STOCK'? true : false);
  return await tmp
}
