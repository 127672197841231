import React from 'react';
import { v4 as uuid } from 'uuid';
import Img from "gatsby-image"

const HeadingDescriptionIcons = ({ description, heading, icons }) => {
  return (
    <section className="productSingleContent__HeadingDescriptionIcons">
      <div className="container">
        <h2 className="productSingleContent__HeadingDescriptionIcons__heading heading--big heading--center heading--dash">
          <span dangerouslySetInnerHTML={{ __html: heading }}></span>
        </h2>
        {description ? (
          <div className="content">
            <div className="description" dangerouslySetInnerHTML={{ __html: description }}>
            </div>
          </div>
        ) : ''}
        {icons ?
          <div className="productSingleContent__HeadingDescriptionIcons__wrapper">
            {icons.map(icon => (
              <div key={uuid()} className="productSingleContent__HeadingDescriptionIcons__item">
                <div className="productSingleContent__HeadingDescriptionIcons__item__icon">
                  <Img fluid={icon.icon.imageFile.childImageSharp.fluid} />
                </div>
                <div className="productSingleContent__HeadingDescriptionIcons__item__description">
                  <div dangerouslySetInnerHTML={{ __html: icon.description }} />
                </div>
              </div>
            ))}
          </div>
          : ''}
      </div>
    </section>
  )
}
export default HeadingDescriptionIcons
