import React from 'react';
import Button from '../button/Button';
import ButtonAddToCart from '../buttonAddToCart/ButtonAddToCart';
import Spin from '../spin/Spin';

import "./style.scss"

const ProductSingleBottom = ({
  footerAction,
  footerText,
  productId,
  loading,
  canBuy,
  textOutOfStock,
  dataProductForPixel
}) => {
  const handleScrollUp = (e) => {
    if ((typeof window !== "undefined")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }

  return (
    <div className="productSingleBottom">
      <div className="container">
        <div className="productSingleBottom__wrapper">
          {footerAction === 'scroll_up' && <Button title={footerText} url='#' action={(event => handleScrollUp(event))} />}
          {footerAction === 'add_to_cart' ? (
            <>
              {canBuy ?
                (
                  <>
                    {footerAction === 'add_to_cart' && <ButtonAddToCart title={footerText} quantity={1} productID={productId} dataProductForPixel={dataProductForPixel} />}
                  </>
                ) :
                (
                  <>
                    <Button deactive={true} title={footerText} url='#' />
                    {!loading ?
                      (
                        <div className="productSingleBottom__info">
                          <p class="productSingleHero__buy__cant">{textOutOfStock}</p>
                        </div>
                      )
                      : ''}
                  </>
                )
              }
            </>
          ) : ''}
          {loading ? (
            <Spin />
          ) : ''}
        </div>
      </div>
    </div>
  )
}
export default ProductSingleBottom
