import React, { useEffect } from "react"
import { isEmpty } from "lodash"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { getCookie, getOgImage, priceToFloat } from "../../utils/functions"
import ProductSingle from "../../components/productSingle/ProductSingle"
import "./style.scss"
import { Helmet } from "react-helmet"
import { WPViewContentProducts } from "../../utils/WPpixel"

const ProductSinglePage = props => {
  const {
    pageContext: { name, seo, uri },
  } = props
  // console.log(props)
  const { code: locale } = props.pageContext.wpmlTranslated.current[0]
  const { node: translated } = props.pageContext.wpmlTranslated
  const { pageContext } = props
  const lang = locale.substring(0, 2)

  useEffect(() => {
    let floatPrice = priceToFloat(pageContext.regularPrice)
    if (pageContext.salePrice) {
      floatPrice = priceToFloat(pageContext.salePrice)
    }


    if (typeof window !== 'undefined'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 
        event: "view_item",
        ecommerce:{
          currencyCode: 'PLN',
          currency: "PLN",
          value: floatPrice,
          detail: {
            products: [
              {
                id: pageContext.databaseId,
                name: pageContext.name,
                sku: pageContext.databaseId,
                category: 'Żywność, napoje i tytoń > Żywność',
                price: floatPrice,
                stocklevel: null
              }
            ]
          },
          items: [
            {
              item_id: pageContext.databaseId,
              item_name: pageContext.name,
              item_brand: "",
              price: floatPrice,
              item_category: 'Żywność, napoje i tytoń > Żywność',
              id: pageContext.databaseId
            }
          ]
        }
       });
    }

    // console.log(pageContext)
    WPViewContentProducts('ViewProduct', [
      {
        id: pageContext.databaseId,
        name: pageContext.name,
        price: floatPrice,
        category: 'Żywność, napoje i tytoń > Żywność'
      },
    ])
  }, [])

  return (
    <Layout lang={lang} page="productsSingle" translated={translated}>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={name}
            translated={translated}
            seoData={seo}
            uri={uri}
            lang={lang}
            page={"productsSingle"}
            // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
            openGraphImage={getOgImage(seo)}
          />
          {getCookie("gatsby-gdpr") ? (
            <Helmet>
              <script
                type="text/javascript"
                ata-rh="true"
                data-react-helmet="true"
                src={`https://t.goadservices.com/engine/${
                  process.env.goPl
                }?id=${getCookie("__goadservices")}`}
              ></script>
              <script
                type="text/javascript"
                ata-rh="true"
                data-react-helmet="true"
              >
                {`
              var goadservicesq = goadservicesq || [];
              try {
                goadservicesq.push(
                    [
                        "_PRODUCT",
                        {
                            identifier: '${props.pageContext.databaseId}',
                            availability: '${
                              props.pageContext.stockQuantity > 0 ? 1 : 0
                            }'
                        }
                    ]

                );
              } catch (e) {
                  // console.log(e)
              }
            `}
              </script>
            </Helmet>
          ) : (
            ""
          )}
          <ProductSingle content={pageContext} lang={lang} />
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  )
}
export default ProductSinglePage
