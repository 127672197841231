import React, { useEffect, useState } from "react"
import "./style.scss"
import Img from "gatsby-image"
import Breadcrumbs from "../breadcrumbs/Breadcrumbs"
import Tags from "../tags/Tags"
import Trustpilot from "../trustpilot/Trustpilot"
import ProductSingleHeroMergeProducts from "./ProductSingleHeroMergeProducts"
import Button from "../button/Button"
import ButtonAddToCart from "../buttonAddToCart/ButtonAddToCart"
import Spin from "../spin/Spin"
// import { addProductToCart } from '../../woocommerce_api/postProductToCart';
import { isEmpty } from "lodash"
import { priceToFloat } from "../../utils/functions"
import { Link } from "gatsby"
import SubscriptionIcon from "../../images/subscription_info.svg"
import ProductSingleGallery from "../productSingleGallery/ProductSingleGallery"

const ProductSingleHero = ({
  background,
  categories,
  tags,
  title,
  description,
  lang,
  mergerProducts,
  regularPrice,
  customPrice,
  salePrice,
  addToCartText,
  breadcrumbsStart,
  textOutOfStock,
  loading,
  canBuy,
  productId,
  buySingleText,
  buySubscriptionText,
  productSubscriptionAcf,
  setCurrentProductID,
  singleProductId,
  productImage,
  addToCartError,
  addToCartErrorIdProduct,
  setCurrentSalePrice,
  setCurrentRegularPrice,
  currentSalePrice,
  currentRegularPrice,
  subskryptionPage,
  currentOmnibus,
  setCurrentOmnibus,
  omnibus,
  galleryImages,
}) => {
  const [breadcrumbsList, setBreadcrumbsList] = useState([])
  const [quality, setQuality] = useState(1)
  // const [currentSalePrice, setCurrentSalePrice] = useState(salePrice)
  // const [currentRegularPrice, setCurrentRegularPrice] = useState(regularPrice)
  const [productType, changeProductType] = useState("single")

  let floatPrice = priceToFloat(currentRegularPrice)
  if (salePrice) {
    floatPrice = priceToFloat(currentSalePrice)
  }

  const dataProductForPixel = {
    id: productId,
    name: title,
    price: floatPrice,
    quantity: quality,
    category: "Żywność, napoje i tytoń > Żywność",
  }

  useEffect(() => {
    let tmpBreadcrumbsList = []

    breadcrumbsStart.forEach(element => {
      tmpBreadcrumbsList.push({ ...element })
    })

    categories.nodes.forEach(element => {
      tmpBreadcrumbsList.push({
        breadcrumb: {
          url: element.uri,
          title: element.name,
          target: "",
        },
      })
    })
    setBreadcrumbsList(tmpBreadcrumbsList)
    // console.log(tmp);
  }, [])

  const qualityChange = (e, action = false) => {
    if (action === "down") {
      if (quality - 1 > 0) {
        setQuality(quality - 1)
      }
    }
    if (action === "up") {
      if (quality + 1 < 31) {
        setQuality(quality + 1)
      }
    }
    if (!action) {
      setQuality(e.target.value)
      if (+e.target.value - 1 < 0) {
        setQuality(1)
      } else if (+e.target.value + 1 > 31) {
        setQuality(30)
      }
    }
  }

  const handleChangeType = e => {
    changeProductType(e.target.value)
    if (e.target.value === "single") {
      setCurrentProductID(singleProductId)
      setCurrentSalePrice(salePrice)
      setCurrentRegularPrice(regularPrice)
      setCurrentOmnibus(omnibus)
    } else {
      setCurrentProductID(productSubscriptionAcf.databaseId)
      setCurrentSalePrice(productSubscriptionAcf.salePrice)
      setCurrentRegularPrice(productSubscriptionAcf.customPrice)
      setCurrentOmnibus(productSubscriptionAcf.omnibus)
    }
  }
  console.log(galleryImages.nodes)
  return (
    <section className="productSingleHero" style={{ background: "#eeebea" }}>
      {!isEmpty(galleryImages.nodes) ? (
        <ProductSingleGallery images={galleryImages.nodes}></ProductSingleGallery>
      ) : (
        <div className="productSingleHero__baground">
          <Img fluid={background.imageFile.childImageSharp.fluid} />
        </div>
      )}
      <div className="productSingleHero__content">
        <div className="productSingleHero__breadcrumbs">
          <div className="container">
            <Breadcrumbs breadcrumbsList={breadcrumbsList} />
          </div>
        </div>
        <div className="container">
          <div className="productSingleHero__wrapper">
            <div className="productSingleHero__col productSingleHero__col--free">
              {/* <Img fluid={productImage.imageFile.childImageSharp.fluid} /> */}
            </div>
            <div className="productSingleHero__col productSingleHero__col--content">
              {tags && (
                <div className="productSingleHero__tags">
                  <Tags tags={tags.nodes} />
                </div>
              )}
              <h1 className="heading--big productSingleHero__heading">
                {title}
              </h1>
              <Trustpilot small={true} lang={lang} background="transparent" />
              <div className="productSingleHero__description">
                <div dangerouslySetInnerHTML={{ __html: description }} />
              </div>
              {mergerProducts && (
                <div className="productSingleHero__mergeProducts">
                  {mergerProducts.map((element, index) => (
                    <div
                      className="productSingleHero__mergeProducts__item"
                      key={"mergeProducts__" + index}
                    >
                      <ProductSingleHeroMergeProducts
                        mergerProducts={element}
                      />
                    </div>
                  ))}
                </div>
              )}
              <div className="productSingleHero__buy">
                {!isEmpty(productSubscriptionAcf) ? (
                  <div className="productSingleHero__buy__type">
                    {buySingleText ? (
                      <div className="form__radio" key="single">
                        <label htmlFor="single">
                          <input
                            type="radio"
                            checked={productType === "single" ? "checked" : ""}
                            name="productType"
                            value="single"
                            id="single"
                            onChange={e => {
                              handleChangeType(e)
                            }}
                          />
                          <span className="form__radio__label">
                            <span
                              className="form__radio__label__heading"
                              dangerouslySetInnerHTML={{
                                __html: buySingleText,
                              }}
                            />
                          </span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {buySubscriptionText ? (
                      <div className="form__radio" key="subscription">
                        <label htmlFor="subscription">
                          <input
                            type="radio"
                            checked={
                              productType === "subscription" ? "checked" : ""
                            }
                            name="productType"
                            value="subscription"
                            id="subscription"
                            onChange={e => {
                              handleChangeType(e)
                            }}
                          />
                          <span className="form__radio__label">
                            <span className="form__radio__label__heading">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: buySubscriptionText,
                                }}
                              />
                              <Link
                                style={{ marginLeft: "5px", marginTop: "-2px" }}
                                to={`${subskryptionPage.url}`}
                                target={`${
                                  subskryptionPage.target
                                    ? subskryptionPage.target
                                    : "_self"
                                }`}
                              >
                                <SubscriptionIcon />
                              </Link>
                              {/* {" - "}
                              <strong
                                dangerouslySetInnerHTML={{
                                  __html: productSubscriptionAcf.regularPrice,
                                }}
                              /> */}
                            </span>
                          </span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className="productSingleHero__buy__wrapper">
                  <div className="form__input productSingleHero__buy__input">
                    <button
                      className="quality__down"
                      onClick={e => {
                        e.preventDefault()
                        qualityChange(e, "down")
                      }}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={quality}
                      onChange={e => {
                        e.preventDefault()
                        qualityChange(e)
                      }}
                    />
                    <button
                      className="quality__up"
                      onClick={e => {
                        e.preventDefault()
                        qualityChange(e, "up")
                      }}
                    >
                      +
                    </button>
                  </div>
                  {addToCartError && +productId === addToCartErrorIdProduct ? (
                    <>
                      <div className="productSingleHero__buy__button">
                        <ButtonAddToCart
                          title={addToCartText}
                          quantity={quality}
                          productID={productId}
                          dataProductForPixel={dataProductForPixel}
                        />
                        {!loading ? (
                          <p className="productSingleHero__buy__cant">
                            {addToCartError}
                          </p>
                        ) : (
                          <Spin />
                        )}
                      </div>
                    </>
                  ) : canBuy ? (
                    <>
                      <div className="productSingleHero__buy__button">
                        <ButtonAddToCart
                          title={addToCartText}
                          quantity={quality}
                          productID={productId}
                          dataProductForPixel={dataProductForPixel}
                        />
                        {loading && <Spin />}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="productSingleHero__buy__button">
                        {loading ? (
                          <>
                            <div style={{ position: "relative" }}>
                              <Button
                                deactive={true}
                                title={addToCartText}
                                url="#"
                              />
                              <Spin />
                            </div>
                          </>
                        ) : (
                          <>
                            <Button
                              deactive={true}
                              title={addToCartText}
                              url="#"
                            />
                            <p className="productSingleHero__buy__cant">
                              {textOutOfStock}
                            </p>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>

                <div className="productSingleHero__buy__price">
                  {currentSalePrice ? (
                    <>
                      <del className="productSingleHero__buy__price--old">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: currentRegularPrice,
                          }}
                        ></span>
                      </del>
                      <span className="productSingleHero__buy__price--new">
                        <span
                          dangerouslySetInnerHTML={{ __html: currentSalePrice }}
                        ></span>
                      </span>
                    </>
                  ) : (
                    <span className="productSingleHero__buy__price--normal">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: currentRegularPrice,
                        }}
                      ></span>
                    </span>
                  )}

                  {productType === "single" &&
                  !isEmpty(productSubscriptionAcf) &&
                  buySubscriptionText ? (
                    <>
                      <p className="productSingleHero__subscription__price">
                        <strong
                          dangerouslySetInnerHTML={{
                            __html: productSubscriptionAcf.regularPrice,
                          }}
                        ></strong>
                        <span> (cena w subskrypcji)</span>
                      </p>
                    </>
                  ) : (
                    ""
                  )}

                  {currentOmnibus ? (
                    <>
                      <p
                        className="productSingleHero__buy__price--onibus"
                        dangerouslySetInnerHTML={{
                          __html: currentOmnibus,
                        }}
                      ></p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default ProductSingleHero
