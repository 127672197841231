import React, { useState, useEffect, useRef } from "react"
import { Navigation, Controller, Pagination, Thumbs } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Img from "gatsby-image"

import "./style.scss"
import Left from "../../images/slider_left.svg"
import Right from "../../images/slider_right.svg"

const ProductSingleGallery = ({ images }) => {
  const [swiper, setSwiper] = useState()
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  const prevRef = useRef()
  const nextRef = useRef()

  const prevTumbRef = useRef()
  const nextTumbRef = useRef()
  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current
      swiper.params.navigation.nextEl = nextRef.current
      swiper.navigation.init()
      swiper.navigation.update()
    }
  }, [swiper])

  useEffect(() => {
    if (thumbsSwiper) {
      thumbsSwiper.params.navigation.prevEl = prevTumbRef.current
      thumbsSwiper.params.navigation.nextEl = nextTumbRef.current
      thumbsSwiper.navigation.init()
      thumbsSwiper.navigation.update()
    }
  }, [thumbsSwiper]);

  return (
    <div className="productSingleGallery">
      <div className="productSingleGallery__swiper">
        <div className="swiper-button swiper-button--left" ref={prevRef}>
          <Left />
        </div>
        <Swiper
          // install Swiper modules
          modules={[Navigation, Controller, Thumbs]}
          slidesPerView={1}
          spaceBetween={0}
          // pagination={{ clickable: true }}
          //   observer
          //   observeParents
          onSwiper={setSwiper}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          thumbs={{ swiper: thumbsSwiper }}
        >
          {images.map((item, index) => {
            console.log(item.imageFile)
            return item.imageFile ? (
              <SwiperSlide
                key={`productSingleGallery__item__${index}`}
                className="productSingleGallery__item"
              >
                <Img fluid={item?.imageFile?.childImageSharp?.fluid} />
              </SwiperSlide>
            ) : (
              ""
            )
          })}
        </Swiper>
        <div className="swiper-button swiper-button--right" ref={nextRef}>
          <Right />
        </div>
      </div>
      <div className="productSingleGallery__thums">
        <div className="swiper-button swiper-button--left" ref={prevTumbRef}>
          <Left />
        </div>
        <Swiper
          modules={[Thumbs, Navigation, Controller]}
          watchSlidesProgress
          onSwiper={setThumbsSwiper}
          navigation={{
            prevEl: prevTumbRef?.current,
            nextEl: nextTumbRef?.current,
          }}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1300: {
              slidesPerView: 4,
            },
          }}
        >
          {images.map((item, index) =>
            item.imageFile ? (
              <SwiperSlide
                key={`productSingleGalleryThum__item__${index}`}
                className="productSingleGalleryThum__item"
              >
                <Img fluid={item?.imageFile?.childImageSharp?.fluid} />
              </SwiperSlide>
            ) : (
              ""
            )
          )}
        </Swiper>
        <div className="swiper-button swiper-button--right" ref={nextTumbRef}>
          <Right />
        </div>
      </div>
    </div>
  )
}
export default ProductSingleGallery
