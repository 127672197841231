import React from 'react';
import "./style.scss"
import Trustpilot from '../trustpilot/Trustpilot';
import HeadingDescriptionIcons from './HeadingDescriptionIcons';
import HeadingDesriptionImageList from './HeadingDesriptionImageList';
import Banner from './Banner';

const ProductSingleBuilder = ({ content, lang }) => {
  return (
    <div className="productSingleContent">
      {content.map((elemnt, index) => (
        <div key={'productSingleContent_' + index}>
          {
            elemnt.__typename === "WPGraphQL_SimpleProduct_Acfsingleproduct_Content_HeadingDescriptionIcons" &&
            <HeadingDescriptionIcons description={elemnt.description} heading={elemnt.heading} icons={elemnt.icons} />
          }
          {
            elemnt.__typename === "WPGraphQL_SimpleProduct_Acfsingleproduct_Content_HeadingDesriptionImageList" &&
            <HeadingDesriptionImageList description={elemnt.description} heading={elemnt.heading} list={elemnt.list} img={elemnt.productImage} />
          }
          {
            elemnt.__typename === "WPGraphQL_SimpleProduct_Acfsingleproduct_Content_Trustpilot" &&
            <Trustpilot lang={lang} background="transparent" />
          }
          {
            elemnt.__typename === "WPGraphQL_SimpleProduct_Acfsingleproduct_Content_Banner" &&
            <Banner textAfterButton={elemnt.testAfterButton} textBeforeButton={elemnt.textBeforeButton} heading={elemnt.heading} button={elemnt.button} backgroundImage={elemnt.backgroundImage} />
          }

        </div>
      ))}
    </div>
  )
}
export default ProductSingleBuilder
