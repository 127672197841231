import React from 'react';
import { removeHost } from "../../utils/functions";
import { Link } from 'gatsby';
import { v4 as uuid } from 'uuid';


const ProductSingleHeroMergeProducts = ({ mergerProducts }) => {
  return (
    <div className="ProductSingleHeroMergeProducts">
      <div className="ProductSingleHeroMergeProducts__name">
        {mergerProducts.attributeGroup}
      </div>
      <div className="ProductSingleHeroMergeProducts__wrapper">
        <div className="ProductSingleHeroMergeProducts__group">
          {mergerProducts.attribute.map(item => (
            item.product?.url ? (
              <Link key={uuid()} to={removeHost(item.product.url)} className={`ProductSingleHeroMergeProducts__group__item ${item.active ? "ProductSingleHeroMergeProducts__group__item--active" : ''}`}>
                {item.name}
              </Link>
            ) :
              ''
          ))}
        </div>
        {mergerProducts.attributeActiveDescription && (
          <div className="ProductSingleHeroMergeProducts__details" >
            <span className="ProductSingleHeroMergeProducts__details__bg" style={{ width: mergerProducts.attributeActivePercent + '%' }}></span>
            <span className="ProductSingleHeroMergeProducts__details__name" style={{ width: mergerProducts.attributeActivePercent + '%' }}>{mergerProducts.attributeActiveDescription}</span>
          </div>
        )}
      </div>
    </div>
  )
}
export default ProductSingleHeroMergeProducts
