import React from 'react';
import Img from "gatsby-image"
import Button from '../button/Button';
import { removeHost } from "../../utils/functions";


const Banner = ({ textAfterButton, textBeforeButton, heading, button, backgroundImage }) => {
  return (
    <section className="productSingleContent__Banner">
      <div className="productSingleContent__Banner__img">
        <Img fluid={backgroundImage.imageFile.childImageSharp.fluid} />
      </div>
      <div className="productSingleContent__Banner__content">
        <div className="container">
          <div className="productSingleContent__Banner__wrapper">
            <h2 className="productSingleContent__HeadingDesriptionImageList__heading heading--big heading--dash">
              <span dangerouslySetInnerHTML={{ __html: heading }}></span>
            </h2>
            {textBeforeButton && (
              <div className="content productSingleContent__Banner__textBeforeButton">
                <div className="description" dangerouslySetInnerHTML={{ __html: removeHost(textBeforeButton) }}>
                </div>
              </div>
            )}
            {button && (
              <div className="productSingleContent__Banner__button">
                <Button title={button.title} url={button.url} target={button.target} />
              </div>
            )}
            {textAfterButton && (
              <div className="content productSingleContent__Banner__textAfterButton">
                <div className="description" dangerouslySetInnerHTML={{ __html: removeHost(textAfterButton) }}>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
export default Banner
